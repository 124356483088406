import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/gad_blog_header.png");
const section_1 = require("../../../assets/img/blogs/gad_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/gad_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/gad_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/gad_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/gad_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/gad_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/gad_blog_img_7.png");
const section_8 = require("../../../assets/img/blogs/gad_blog_img_8.png");
const section_9 = require("../../../assets/img/blogs/gad_blog_img_9.png");
const section_10 = require("../../../assets/img/blogs/gad_blog_img_10.png");
const section_11 = require("../../../assets/img/blogs/gad_blog_img_11.png");
const section_12 = require("../../../assets/img/blogs/gad_blog_img_12.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Generative AI for Dummies (what, how, why for companies)"
        description=" Here is a Generative AI guide to help you understand the fundamental functions of LLM-powered models. Learn to empower your knowledge about Generative AI."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt gad_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img ${
                  isMobile ? "text-align-center" : "float-left"
                }`}
              >
                {isMobile ? (
                  <>
                    <h1 className="font-page-header-home-blog">
                      Generative AI for Dummies
                    </h1>
                    <h2 className="font-section-sub-header-blog-title">
                      ( what, how, why for companies)
                    </h2>
                  </>
                ) : (
                  <>
                    <h1 className="font-page-header-home-blog">
                      Generative AI for Dummies
                    </h1>
                    <h2 className="font-section-sub-header-blog-title">
                      ( what, how, why for companies)
                    </h2>
                  </>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What do you understand by Generative AI?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. How Generative AI works?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. The evolution of Generative AI
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. What are the ways you can put Gen AI to work at scale?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Train, fine-tune, or as is, models
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Conclusion
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                At the very least, you may have heard that students are frenzied
                about ChatGPT’s application to use it for doing their homework
                or school projects.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Or you could get to know that applicants are seen using ChatGPT
                to write cover letters.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is a very basic level application of ChatGPT, or Generative
                Pre-trained Transformer, that underpins the ChatGPT, a web chat
                interface to be used as a QnA tool for query resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, can you guess, amidst the economic challenges, what else is
                currently at the epicenter of the discussion points for CEOs?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                While every business strategy is to optimize cash flow or
                working capital right now,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  CEOs are strategizing different ways in which they can put
                  <a
                    href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide
"
                  >
                    Generative AI
                  </a>{" "}
                  to work that yields high-level business value for them.
                </span>{" "}
                And to do this, their eagerness to invest in emerging
                technologies like Generative AI is quite palpable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Behind the boardroom meets, Generative AI hovers over the minds
                of the CEOs.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                What the latest report,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  “What CEOs talked about,”
                </span>{" "}
                brought to the table is quite surprising.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://iot-analytics.com/what-ceos-talked-about-q2-2023/">
                  It is revealed that Generative AI topped the discussion agenda
                  for CEOs in Q2/2023
                </a>{" "}
                compared to the other two vulnerable matters (bank troubles and
                uncertain economy).
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                While Generative AI and its related use cases and applications
                continue to increase for boardroom discussions, the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  keyword itself sees a significant rise of +129% in the last
                  quarter.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Can you assume why?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Why is it that about 55% of CEOs surveyed for{" "}
                <a href="https://www2.deloitte.com/us/en/pages/chief-executive-officer/articles/ceo-survey.html">
                  Summer 2023 Fortune/Deloitte CEO Survey Insight,
                </a>{" "}
                confirm to have started experimenting with Generative AI, or 79%
                of them believe Gen AI will increase efficiencies?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to know why, here’s a bit of a clue for you 一 it is
                only because Generative AI holds a huge promise for business
                functions, given the fact it is built on{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  large language models
                </span>
                more powerful than any regular AI models best applied to
                generate new responses or content to reimagine various
                <span className="font-section-normal-text-testimonials-medium color-black">
                  industry-specific use cases.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s dive deep!
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What do you understand by Generative AI?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                If you know what artificial intelligence is, Generative AI seems
                simple to understand too.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Traditional AI vs. Generative AI comparison table"
              ></img>
              <p class="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Generative AI stands for Generative Artificial Intelligence,
                </span>
                meaning its architecture is also fed with machine learning
                components similar to AI tools. However, one striking difference
                between regular AI and Generative AI is their ability to perform
                functions.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As with AI, you can use data to concentrate on one area. For
                example, collecting transactions-related data and using it to
                create a model that identifies anomaly patterns and helps detect
                fraud.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Similarly, AI models can tap into limited business function data
                and help improve process efficiency. Overall, traditional AI
                aims to make predictions and deliver results ahead of time.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However, Generative AI can perform outside of one specific
                business function. As its name suggests,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  ‘Generative,’;
                </span>{" "}
                it usually performs as a{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  generator model
                </span>{" "}
                that can generate anything using a prompt in real time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A prompt is a question in a Gen AI model interface that
                processes data to build a relationship with the input and
                surfaces the most relevant output.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if you ask a Generative AI platform,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  “What is the difference between a cow and a bull?”
                </span>{" "}
                it finds out the patterns and features of the animals and
                provides appropriate answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI does this by using its large language model
                architecture, which harnesses massive{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  corpora of texts, primarily extracted from wikis, the
                  internet, ebooks, websites, research papers, and lots more,
                </span>{" "}
                to apply algorithms and find answers using deep learning or
                neural network-based technologies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to write code, Generative AI does that. Or if you
                want Gen AI to find errors in codes, Generative AI will also do
                that.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The possibilities are immense, more than what you can do with
                traditional AI tools.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How Generative AI works?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI accesses massive datasets, underpinning{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  natural language processing (NLP)
                </span>{" "}
                models such as Large Language Models.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                These models have two versions.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    1.{" "}
                  </span>
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    GANs or Generative Adversarial Networks
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    2.{" "}
                  </span>
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Transformer-based models
                  </span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                For a basic-level understanding of how Generative AI works,
                let’s assume that a prompt is inserted into a Gen AI interface.
                Gen AI synthesizes or breaks down data,, matches the most
                relevant answers in the datasets, and provides the output.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="A simple representation of NLP task processing by Gen AI"
              ></img>

              <p className="font-section-normal-text-testimonials line-height-18">
                The behind-the-scenes natural language processing to surface
                appropriate responses using Gen AI models is intricate.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Both GANs and Transformer models apply different approaches to
                generate output.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Generative Adversarial Networks Models
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                In this model, the primary objective of GANs is to identify a
                difference between a fake or original sample and provide the
                correct output, the result of which also redirects to the
                underlying neural networks to improve their performance.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="GAN architecture of Generative AI"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                GANs consist of two parts,
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Generator - a neural network that deliberately generates
                    fake data when fed with random input. Interestingly, what
                    Generator produces becomes training data for the
                    discriminator.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Discriminator - also a neural network to identify between
                    fake samples created by Generator and original data from the
                    training dataset.
                  </span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                The Discriminator uses a binary classifier to detect a
                difference between fake and real. So, if the classification
                result is close to 0, it is considered fake; while it is closer
                to 1, it is real.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, whatever the result is, it is updated in any of the neural
                networks and provides a template for both to learn.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The Discriminator neural network learns from the negative sample
                results that the Generator produces and thus improves its
                accuracy in identifying real data. And vice versa, the Generator
                NN does the same to fake data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A plethora of functions can be accomplished using GAN-based
                Generative AI models that include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Removing noise from data,
                </li>
                <li className="font-section-normal-text-testimonials">
                  Image-to-image translation,
                </li>
                <li className="font-section-normal-text-testimonials">
                  3D object generation,
                </li>
                <li className="font-section-normal-text-testimonials">
                  Face frontal view generation, etc.
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                All of these applications can provide massive opportunities for
                healthcare use cases.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Transformer Models
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                When discussing transformer models, they are widely used now for
                a variety of use cases across various business functions.
              </p>
              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                Transformer models are basically built with massive datasets or
                deep learning neural networks known as large language models or
                natural language models to parse human language.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                More often,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Transformer models are also called Foundation models
                </span>
                due to the flexibility to fine-tune with industry-specific data
                and use them to perform business-specific tasks.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The most common example of a Transformer model is none less than
                the popular Generative AI chat interface{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  - ChatGPT.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Look at its suffix. GPT stands for{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Generative Pre-trained Transformer.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It means GPTs are pre-trained with a huge corpus of data to
                parse any natural language and mimic human intelligence to
                produce accurate output.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, the more accurate data is the more accurate the output.
                If a GPT contains wrong or faulty data, it produces wrong
                information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Transformer models synthesize data using their encoder and
                decoder components to provide the output.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Simply put, a transformer model encodes input data, then decodes
                it with the expected output result, and then generates an
                output.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Output processing in Transformer model of Generative AI architecture"
              ></img>

              <p className="font-section-normal-text-testimonials line-height-18">
                The underlying functioning of the transformer model is to parse
                data sequence-to-sequence using semi-supervised or unsupervised
                learning and produce the response.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                When it focuses on{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  sequence-to-sequence learning,
                </span>{" "}
                it means a transformer model is trained to identify the next
                sequential word or phrase in a query and to produce output based
                on the same theory.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At the core, a Generative Pre-trained Transformer model
                identifies contexts in the input rather than finding words that
                match the input.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                For example, if a user wants to ask, “Who is the President of
                the US?”, the transformer model would encode these words and
                sends them for decoding. A transformer model would then search
                its database, build a relationship with the input, and provide
                the right output: "Joe Biden is the present President of the
                US.”
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" an example of output processing in a transformer model"
              ></img>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                A transformer model consists of two parts 一
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    An encoder converts input sequences into tokens, turns them
                    into vector embeddings or numeric representations, and
                    transfers them to the decoder.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    A decoder matches contexts and sequences between encoded
                    inputs and probable output sequences and then produces the
                    right output.
                  </span>
                </li>
              </ul>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Behind-the-scene input processing in a transformer model"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                OpenAI’s ChatGPT or GPT-3, BERT, or RoBERTa are a few examples
                of transformer models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These models can either be fine-tuned with specific industry
                data or integrated with the business applications through the
                API layer to perform specific business operations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GPTs can be used to apply to various use cases, such as,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Answering questions and answers to users
                </li>
                <li className="font-section-normal-text-testimonials">
                  Aiding customer support to provide product recommendations
                </li>
                <li className="font-section-normal-text-testimonials">
                  Solving customer problems in real-time
                </li>
                <li className="font-section-normal-text-testimonials">
                  Drafting a sales document in various formats, i.e., excel,
                  slides, etc
                </li>
                <li className="font-section-normal-text-testimonials">
                  Automating various tasks such as text generation, email copy
                  generation, etc
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The evolution of Generative AI
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The sudden rise in popularity of ChatGPT has reopened the
                discussion of Generative AI or large language models and
                triggered an AI race.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI is not a new phenomenon. Businesses of all sizes
                are keen to evaluate the best aspect of this emerging technology
                and apply it to their workflows to maximize business benefits.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s find out how Generative AI has evolved.
              </p>
              <h3 className="font-section-sub-header-blog">
                Generative AI milestones or timeline
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="A timeline of Generative AI milestone                 "
              ></img>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  2014 - GANs or Generative Adversarial Networks introduced by
                  Ian Goodfellow
                </li>
                <li className="font-section-normal-text-testimonials">
                  2015 - An attention model by Dzmitry Bahdanau to reduce the
                  complexity of recognizing longer sentences by considering only
                  words to generate the right response
                </li>
                <li className="font-section-normal-text-testimonials">
                  2017- A transformer model by Ashish Vaswani to work based on
                  attention mechanisms and deep neural networks instead of
                  recurrent neural networks
                </li>
                <li className="font-section-normal-text-testimonials">
                  2018 - GPT 1 by OpenAI built with supervised learning via
                  manually labeled data
                </li>
                <li className="font-section-normal-text-testimonials">
                  2019- GPT 2, the second foundation series from OpenAI, with
                  1.5 billion parameters 一, a 10-fold increase in parameter
                  count, and training datasets
                </li>
                <li className="font-section-normal-text-testimonials">
                  2020- GPT3, a decoder-only transformer model, trained on 175
                  billion parameters that use between zero-shot and few-shot
                  learning
                </li>
                <li className="font-section-normal-text-testimonials">
                  2022- GPT 3.5 Turbo, popularly known as ChatGPT, to provide
                  responses from the Internet up to 2021
                </li>
                <li className="font-section-normal-text-testimonials">
                  2023 - GPT 4, the extended version of GPT 3.5, known to be a
                  large multimodal model to receive inputs in the form of texts
                  and images and produce text output
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                As soon as GPT 3.5 was released, several other companies join
                the large language model race.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Image generation transformer model{" "}
                    <span className="font-section-normal-text-testimonials-medium color-black">
                      Stable Diffusion
                    </span>{" "}
                    arrived as a surprise.{" "}
                    <span className="font-section-normal-text-testimonials-medium color-black">
                      Midjourney and DALL-E
                    </span>{" "}
                    hold a similar promise to reimagine image creation from
                    scratch.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    <span className="font-section-normal-text-testimonials-medium color-black">
                      GitHub’s Copilot
                    </span>{" "}
                    emerges as a great companion for developers to ease their
                    coding jobs.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    <span className="font-section-normal-text-testimonials-medium color-black">
                      Salesforce
                    </span>{" "}
                    integrates Generative AI to reimagine their CRM applications
                    for marketers.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the ways you can put Gen AI to work at scale?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                From supply chain to logistics, finance, healthcare, to fashion,
                Generative AI has massive scopes to apply across various
                business functions and tap into real-time benefits.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s discuss enterprise use cases of Generative AI for your
                business functions.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Customer support redefined
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the application of{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  question and answer,
                </span>{" "}
                customer support can reimagine how they interact with customers
                and address issues. One specific use case is automating
                conversations with customers and allowing them to self-service
                their issues.
              </p>
              <h4 className="font-section-normal-text-medium">Examples</h4>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 1:
                </span>{" "}
                If a customer wants to review a product with different model
                versions, a Gen AI-based chat interface can help recommend
                better options by analyzing her current conversation patterns
                and surfaces models that align with her objectives. For example,
                if it is observed from the ongoing conversation that the
                customer can spend a good amount, the support platform
                recommends a choice based on the observation and help place an
                order.
              </p>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Generative AI-powered conversation for workplace support"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 2:
                </span>{" "}
                Generative AI aims to cut short the customer response time by
                allowing the customer support agent to grab instant chat history
                and help prepare a better response to handle the call. As a
                result, unlike traditional customer support, a customer receives
                better clarification of their questions, and on the other hand,
                there is no wait time for other customers.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Enhancing employee experience
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprises can build seamless employee experience with
                Generative AI built on their enterprise workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Internal employee communication channels or ITSM tools that
                integrate Gen AI solutions can empower employees to solve their
                problems on their own.
              </p>
              <h4 className="font-section-normal-text-medium">Examples</h4>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 1:
                </span>{" "}
                A{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational AI platform from Workativ
                </a>{" "}
                applies Generative AI properties to its underlying architecture
                to help enterprise leaders build their own{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  LLM-powered KB
                </span>{" "}
                and provide industry-specific answers in a straightforward
                manner to solve employee issues in real-time. The best thing
                about the conversational platform is that it offers
                straightforward responses in the chat interface so employees can
                access information and use it to solve their problems.
              </p>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="self-service help for employees with a Generative-AI powered chatbot "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if a user needs to{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  troubleshoot a paper jam
                </span>{" "}
                in the printer, a Gen AI-powered chatbot would ask several
                related problems and then offer the right answer that helps
                address the issue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 2:
                </span>{" "}
                if an employee wants to know how to claim insurance for a
                particular treatment, a workplace chatbot powered by Gen AI will
                surface the information to help him raise a claim without
                friction.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Easing IT operations
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                For IT teams, IT operations are an everyday hardship. They need
                to be steady in ticket handling and provide rapid yet meaningful
                assistance that solves problems quickly.
              </p>
              <BlogCta
                ContentCta="Automate your employee support with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                But, tasks such as IT asset management are repetitive and
                time-consuming, causing IT fatigue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With applications layered with Generative AI properties, IT
                teams can streamline workflows, more flexible and agile than
                traditional AI, and cut off friction from the IT journeys.
              </p>
              <h4 className="font-section-normal-text-medium">Examples</h4>
              <img src={section_10} className="blog_image_top_bt"></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 1:
                </span>{" "}
                An ITSM manager can use Generative AI and steadily track
                employee devices and warn them of serious issues with their
                devices. If it is predicted that an employee is approaching{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  a password expiry,
                </span>{" "}
                a notification is escalated for the employee to help him set his
                password before it expires and causes disruptions in operational
                efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 2:
                </span>{" "}
                Generative AI helps with real-time monitoring and automation.
                So, an incident is detected, it streamlines workflows by sending
                an incident mitigation plan to the right person and helps reduce
                the impact.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Freeing up the HR team
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Onboarding and offboarding is the most mundane work for HR
                teams. An HR team can be seen juggling administrative work and
                onboarding operations. The result is either of the work can
                happen haphazardly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The wait time for new hire onboarding increases, making him
                impatient, whereas administrative tasks pile up, creating a
                backlog.
              </p>

              <h4 className="font-section-normal-text-medium">Examples</h4>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="Employee onboarding                 "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 1:
                </span>{" "}
                Generative AI streamlines the onboarding processes by automating
                mundane tasks such as facilitating documentation, scheduling new
                hire introduction meet, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 2:
                </span>{" "}
                Generative AI makes the knowledge base easily accessible to help
                a new hire learn company policies and various operation aspects.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Sales and marketing
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                There are myriad ways sales and marketing can benefit from
                Generative AI. Marketing and sales must build brand awareness
                through different materials for social media posts or website
                publications. Generative AI helps reduce the time to create and
                polish a draft with meaningful content.
              </p>

              <h4 className="font-section-normal-text-medium">Examples</h4>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 1:
                </span>{" "}
                Sales and marketing must communicate with the prospects with
                different types of email templates. Generative AI helps reduce
                the labor of creating a draft from scratch, generating it
                instantly to revise and use for communications. As a result,
                sales and marketing can focus more on the human aspect of
                collaboration and building relationships.
              </p>
              <img
                src={section_12}
                className="blog_image_top_bt"
                alt="Content generation using Generative AI"
              ></img>
              <h3 className="font-section-sub-header-small-home">
                Can Generative AI be trustworthy?
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI or its foundation models are built based on
                unsupervised learning. The chances are high for the models to
                hallucinate. Or it can even lack explainability, meaning
                Generative AI cannot explain how it arrives at decisions or
                predictions due to its deep learning models with billions of
                parameters.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The best way to use Generative AI ethically is to provide enough
                human oversight to ensure it does not surface biased or harmful
                responses. It can be done during the model training or
                fine-tuning.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, model training should be based on clean data, a high-level
                task requiring intense human oversight.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In addition, the model deployment is not the end of the
                iteration. The process should be ongoing for performance review
                and then for making changes to the model to enable it to perform
                without bias.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Train, fine-tune, or as is, models
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Different scenarios can determine whether a business needs a
                custom, a fine-tuned, or a pre-trained model.
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Custom solutions
                  </span>
                  provide use-case-specific solutions. So, you need quality data
                  to create your workflows. It gives you much control and makes
                  it easy to update the model workings whenever needed.
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Fine-tuned models
                  </span>
                  allow us to tweak only the final layer of the LLM models. This
                  model solution effectively implements one or two use cases for
                  a small project. It requires less computational power and
                  hardware investment.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Pre-trained models
                  </span>
                  such as ChatGPT, Microsoft AI copilot, and Claude are useful
                  if your purpose is generic, such as Q&A only. Through an API
                  integration, you can implement pre-trained models and gain
                  good business results.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                So far, the article aims to provide a basic understanding of
                Generative AI, and we have supplied all the necessary
                information to make you aware of the technology and its
                capabilities.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In the enterprise setting, Generative AI can offer multiple use
                cases that can be applied across a wide range of business
                functions and streamline complicated work processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to savor the taste of Generative AI, you can start
                small, and API-layered Generative AI solutions are a good
                option.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Want to empower your HR and IT operations with Generative AI?
                Workativ virtual assistants can complement your business
                objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://outlook.office365.com/owa/calendar/Bookademo@workativ.com/bookings/">
                  Get in touch with us for a personalized demo
                </a>{" "}
                today.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h2 class="font-section-sub-header-small color-black">
                What are some potential ethical concerns about the
                trustworthiness of Generative AI-generated content and the need
                for human oversight?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM-generated content, like ChatGPT or other GPT 3.5-produced
                content, is known to cause ethical concerns due to its cut-off
                data. Ethical concerns remain a big challenge for custom
                Generative AI solutions, too. While they hold immense potential
                to unlock extended customization for use cases, they can be
                sentient and produce biased or harmful responses, especially if
                they undergo unsupervised learning for training. Hence, ensuring
                human oversight during model training and deployment is crucial
                to mitigate these risks. To improve model performance and
                address biases, a model must undergo continuous monitoring and
                refinement.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                What is the difference between Generative AI and traditional AI,
                depending on their ability to generate real-time responses and
                handle various business functions? Compared to traditional AI,
                is Generative AI suitable for specific industries and use cases?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unlike traditional AI, Generative AI models such as GPTs or
                Generative Pre-trained Transformers leverage large language
                models trained on millions or billions of data parameters to
                gain the ability to generate human-like responses by
                understanding NLP queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This makes Generative AI understand prompts related to industry
                use cases and surface contextual answers for business functions
                from HR operations to customer support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, traditional AI models follow certain business functions
                or predictions. So, their capabilities are limited to specific
                use cases and may not meet the industry's diverse customization
                needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI always stays ahead of traditional AI in terms of
                versatility and real-time response efficiency.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Besides basic applications, how can Generative AI drive business
                value and contribute to strategic objectives for customer
                support or HR operations?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI unlocks incredible efficiencies to augment the
                state of automation for existing business operations to increase
                user experience and streamline operations, driving CEOs to
                invest in Generative AI to leverage extended business benefits.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There are several ways in which businesses can drive value and
                strategic objectives beyond the basic applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, Generative AI can help reimagine customer support
                by reducing MTTR, enhancing response efficiency, and delivering
                hyper-personalization to customer queries. As a result,
                businesses can improve CSAT and NPS, leading to customer
                retention.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, Generative AI can help HR operations automate
                repetitive tasks and streamline operations for a better user
                experience. Onboarding automation is one of the best use cases
                that free HR of repetitive workloads and helps focus on
                strategic initiatives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In essence, Generative AI transforms how businesses manage work
                and deliver value to their stakeholders.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What do you understand by Generative AI?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. How Generative AI works?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. The evolution of Generative AI
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. What are the ways you can put Gen AI to work at scale?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Train, fine-tune, or as is, models
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Conclusion
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                At the very least, you may have heard that students are frenzied
                about ChatGPT’s application to use it for doing their homework
                or school projects.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Or you could get to know that applicants are seen using ChatGPT
                to write cover letters.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is a very basic level application of ChatGPT, or Generative
                Pre-trained Transformer, that underpins the ChatGPT, a web chat
                interface to be used as a QnA tool for query resolution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, can you guess, amidst the economic challenges, what else is
                currently at the epicenter of the discussion points for CEOs?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                While every business strategy is to optimize cash flow or
                working capital right now,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  CEOs are strategizing different ways in which they can put
                  <a
                    href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide
"
                  >
                    Generative AI
                  </a>{" "}
                  to work that yields high-level business value for them.
                </span>{" "}
                And to do this, their eagerness to invest in emerging
                technologies like Generative AI is quite palpable.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Behind the boardroom meets, Generative AI hovers over the minds
                of the CEOs.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                What the latest report,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  “What CEOs talked about,”
                </span>{" "}
                brought to the table is quite surprising.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://iot-analytics.com/what-ceos-talked-about-q2-2023/">
                  It is revealed that Generative AI topped the discussion agenda
                  for CEOs in Q2/2023
                </a>{" "}
                compared to the other two vulnerable matters (bank troubles and
                uncertain economy).
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                While Generative AI and its related use cases and applications
                continue to increase for boardroom discussions, the{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  keyword itself sees a significant rise of +129% in the last
                  quarter.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Can you assume why?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Why is it that about 55% of CEOs surveyed for{" "}
                <a href="https://www2.deloitte.com/us/en/pages/chief-executive-officer/articles/ceo-survey.html">
                  Summer 2023 Fortune/Deloitte CEO Survey Insight,
                </a>{" "}
                confirm to have started experimenting with Generative AI, or 79%
                of them believe Gen AI will increase efficiencies?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to know why, here’s a bit of a clue for you 一 it is
                only because Generative AI holds a huge promise for business
                functions, given the fact it is built on{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  large language models
                </span>
                more powerful than any regular AI models best applied to
                generate new responses or content to reimagine various
                <span className="font-section-normal-text-testimonials-medium color-black">
                  industry-specific use cases.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s dive deep!
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What do you understand by Generative AI?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                If you know what artificial intelligence is, Generative AI seems
                simple to understand too.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Traditional AI vs. Generative AI comparison table"
              ></img>
              <p class="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Generative AI stands for Generative Artificial Intelligence,
                </span>
                meaning its architecture is also fed with machine learning
                components similar to AI tools. However, one striking difference
                between regular AI and Generative AI is their ability to perform
                functions.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                As with AI, you can use data to concentrate on one area. For
                example, collecting transactions-related data and using it to
                create a model that identifies anomaly patterns and helps detect
                fraud.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Similarly, AI models can tap into limited business function data
                and help improve process efficiency. Overall, traditional AI
                aims to make predictions and deliver results ahead of time.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However, Generative AI can perform outside of one specific
                business function. As its name suggests,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  ‘Generative,’;
                </span>{" "}
                it usually performs as a{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  generator model
                </span>{" "}
                that can generate anything using a prompt in real time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A prompt is a question in a Gen AI model interface that
                processes data to build a relationship with the input and
                surfaces the most relevant output.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if you ask a Generative AI platform,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  “What is the difference between a cow and a bull?”
                </span>{" "}
                it finds out the patterns and features of the animals and
                provides appropriate answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI does this by using its large language model
                architecture, which harnesses massive{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  corpora of texts, primarily extracted from wikis, the
                  internet, ebooks, websites, research papers, and lots more,
                </span>{" "}
                to apply algorithms and find answers using deep learning or
                neural network-based technologies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to write code, Generative AI does that. Or if you
                want Gen AI to find errors in codes, Generative AI will also do
                that.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The possibilities are immense, more than what you can do with
                traditional AI tools.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How Generative AI works?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI accesses massive datasets, underpinning{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  natural language processing (NLP)
                </span>{" "}
                models such as Large Language Models.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                These models have two versions.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    1.{" "}
                  </span>
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    GANs or Generative Adversarial Networks
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    2.{" "}
                  </span>
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Transformer-based models
                  </span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                For a basic-level understanding of how Generative AI works,
                let’s assume that a prompt is inserted into a Gen AI interface.
                Gen AI synthesizes or breaks down data,, matches the most
                relevant answers in the datasets, and provides the output.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="A simple representation of NLP task processing by Gen AI"
              ></img>

              <p className="font-section-normal-text-testimonials line-height-18">
                The behind-the-scenes natural language processing to surface
                appropriate responses using Gen AI models is intricate.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Both GANs and Transformer models apply different approaches to
                generate output.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Generative Adversarial Networks Models
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                In this model, the primary objective of GANs is to identify a
                difference between a fake or original sample and provide the
                correct output, the result of which also redirects to the
                underlying neural networks to improve their performance.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="GAN architecture of Generative AI"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                GANs consist of two parts,
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Generator - a neural network that deliberately generates
                    fake data when fed with random input. Interestingly, what
                    Generator produces becomes training data for the
                    discriminator.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Discriminator - also a neural network to identify between
                    fake samples created by Generator and original data from the
                    training dataset.
                  </span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                The Discriminator uses a binary classifier to detect a
                difference between fake and real. So, if the classification
                result is close to 0, it is considered fake; while it is closer
                to 1, it is real.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, whatever the result is, it is updated in any of the neural
                networks and provides a template for both to learn.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The Discriminator neural network learns from the negative sample
                results that the Generator produces and thus improves its
                accuracy in identifying real data. And vice versa, the Generator
                NN does the same to fake data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A plethora of functions can be accomplished using GAN-based
                Generative AI models that include,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Removing noise from data,
                </li>
                <li className="font-section-normal-text-testimonials">
                  Image-to-image translation,
                </li>
                <li className="font-section-normal-text-testimonials">
                  3D object generation,
                </li>
                <li className="font-section-normal-text-testimonials">
                  Face frontal view generation, etc.
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                All of these applications can provide massive opportunities for
                healthcare use cases.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Transformer Models
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                When discussing transformer models, they are widely used now for
                a variety of use cases across various business functions.
              </p>
              <p className="font-section-normal-text-testimonials-medium color-black line-height-18">
                Transformer models are basically built with massive datasets or
                deep learning neural networks known as large language models or
                natural language models to parse human language.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                More often,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Transformer models are also called Foundation models
                </span>
                due to the flexibility to fine-tune with industry-specific data
                and use them to perform business-specific tasks.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The most common example of a Transformer model is none less than
                the popular Generative AI chat interface{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  - ChatGPT.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Look at its suffix. GPT stands for{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Generative Pre-trained Transformer.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It means GPTs are pre-trained with a huge corpus of data to
                parse any natural language and mimic human intelligence to
                produce accurate output.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, the more accurate data is the more accurate the output.
                If a GPT contains wrong or faulty data, it produces wrong
                information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Transformer models synthesize data using their encoder and
                decoder components to provide the output.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Simply put, a transformer model encodes input data, then decodes
                it with the expected output result, and then generates an
                output.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="Output processing in Transformer model of Generative AI architecture"
              ></img>

              <p className="font-section-normal-text-testimonials line-height-18">
                The underlying functioning of the transformer model is to parse
                data sequence-to-sequence using semi-supervised or unsupervised
                learning and produce the response.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                When it focuses on{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  sequence-to-sequence learning,
                </span>{" "}
                it means a transformer model is trained to identify the next
                sequential word or phrase in a query and to produce output based
                on the same theory.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At the core, a Generative Pre-trained Transformer model
                identifies contexts in the input rather than finding words that
                match the input.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                For example, if a user wants to ask, “Who is the President of
                the US?”, the transformer model would encode these words and
                sends them for decoding. A transformer model would then search
                its database, build a relationship with the input, and provide
                the right output: "Joe Biden is the present President of the
                US.”
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" an example of output processing in a transformer model"
              ></img>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                A transformer model consists of two parts 一
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    An encoder converts input sequences into tokens, turns them
                    into vector embeddings or numeric representations, and
                    transfers them to the decoder.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    A decoder matches contexts and sequences between encoded
                    inputs and probable output sequences and then produces the
                    right output.
                  </span>
                </li>
              </ul>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Behind-the-scene input processing in a transformer model"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                OpenAI’s ChatGPT or GPT-3, BERT, or RoBERTa are a few examples
                of transformer models.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These models can either be fine-tuned with specific industry
                data or integrated with the business applications through the
                API layer to perform specific business operations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GPTs can be used to apply to various use cases, such as,
              </p>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Answering questions and answers to users
                </li>
                <li className="font-section-normal-text-testimonials">
                  Aiding customer support to provide product recommendations
                </li>
                <li className="font-section-normal-text-testimonials">
                  Solving customer problems in real-time
                </li>
                <li className="font-section-normal-text-testimonials">
                  Drafting a sales document in various formats, i.e., excel,
                  slides, etc
                </li>
                <li className="font-section-normal-text-testimonials">
                  Automating various tasks such as text generation, email copy
                  generation, etc
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The evolution of Generative AI
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                The sudden rise in popularity of ChatGPT has reopened the
                discussion of Generative AI or large language models and
                triggered an AI race.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI is not a new phenomenon. Businesses of all sizes
                are keen to evaluate the best aspect of this emerging technology
                and apply it to their workflows to maximize business benefits.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s find out how Generative AI has evolved.
              </p>
              <h3 className="font-section-sub-header-blog">
                Generative AI milestones or timeline
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="A timeline of Generative AI milestone                 "
              ></img>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  2014 - GANs or Generative Adversarial Networks introduced by
                  Ian Goodfellow
                </li>
                <li className="font-section-normal-text-testimonials">
                  2015 - An attention model by Dzmitry Bahdanau to reduce the
                  complexity of recognizing longer sentences by considering only
                  words to generate the right response
                </li>
                <li className="font-section-normal-text-testimonials">
                  2017- A transformer model by Ashish Vaswani to work based on
                  attention mechanisms and deep neural networks instead of
                  recurrent neural networks
                </li>
                <li className="font-section-normal-text-testimonials">
                  2018 - GPT 1 by OpenAI built with supervised learning via
                  manually labeled data
                </li>
                <li className="font-section-normal-text-testimonials">
                  2019- GPT 2, the second foundation series from OpenAI, with
                  1.5 billion parameters 一, a 10-fold increase in parameter
                  count, and training datasets
                </li>
                <li className="font-section-normal-text-testimonials">
                  2020- GPT3, a decoder-only transformer model, trained on 175
                  billion parameters that use between zero-shot and few-shot
                  learning
                </li>
                <li className="font-section-normal-text-testimonials">
                  2022- GPT 3.5 Turbo, popularly known as ChatGPT, to provide
                  responses from the Internet up to 2021
                </li>
                <li className="font-section-normal-text-testimonials">
                  2023 - GPT 4, the extended version of GPT 3.5, known to be a
                  large multimodal model to receive inputs in the form of texts
                  and images and produce text output
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                As soon as GPT 3.5 was released, several other companies join
                the large language model race.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Image generation transformer model{" "}
                    <span className="font-section-normal-text-testimonials-medium color-black">
                      Stable Diffusion
                    </span>{" "}
                    arrived as a surprise.{" "}
                    <span className="font-section-normal-text-testimonials-medium color-black">
                      Midjourney and DALL-E
                    </span>{" "}
                    hold a similar promise to reimagine image creation from
                    scratch.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    <span className="font-section-normal-text-testimonials-medium color-black">
                      GitHub’s Copilot
                    </span>{" "}
                    emerges as a great companion for developers to ease their
                    coding jobs.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    <span className="font-section-normal-text-testimonials-medium color-black">
                      Salesforce
                    </span>{" "}
                    integrates Generative AI to reimagine their CRM applications
                    for marketers.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the ways you can put Gen AI to work at scale?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                From supply chain to logistics, finance, healthcare, to fashion,
                Generative AI has massive scopes to apply across various
                business functions and tap into real-time benefits.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s discuss enterprise use cases of Generative AI for your
                business functions.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Customer support redefined
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the application of{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  question and answer,
                </span>{" "}
                customer support can reimagine how they interact with customers
                and address issues. One specific use case is automating
                conversations with customers and allowing them to self-service
                their issues.
              </p>
              <h4 className="font-section-normal-text-medium">Examples</h4>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 1:
                </span>{" "}
                If a customer wants to review a product with different model
                versions, a Gen AI-based chat interface can help recommend
                better options by analyzing her current conversation patterns
                and surfaces models that align with her objectives. For example,
                if it is observed from the ongoing conversation that the
                customer can spend a good amount, the support platform
                recommends a choice based on the observation and help place an
                order.
              </p>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Generative AI-powered conversation for workplace support"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 2:
                </span>{" "}
                Generative AI aims to cut short the customer response time by
                allowing the customer support agent to grab instant chat history
                and help prepare a better response to handle the call. As a
                result, unlike traditional customer support, a customer receives
                better clarification of their questions, and on the other hand,
                there is no wait time for other customers.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Enhancing employee experience
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprises can build seamless employee experience with
                Generative AI built on their enterprise workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Internal employee communication channels or ITSM tools that
                integrate Gen AI solutions can empower employees to solve their
                problems on their own.
              </p>
              <h4 className="font-section-normal-text-medium">Examples</h4>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 1:
                </span>{" "}
                A{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational AI platform from Workativ
                </a>{" "}
                applies Generative AI properties to its underlying architecture
                to help enterprise leaders build their own{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  LLM-powered KB
                </span>{" "}
                and provide industry-specific answers in a straightforward
                manner to solve employee issues in real-time. The best thing
                about the conversational platform is that it offers
                straightforward responses in the chat interface so employees can
                access information and use it to solve their problems.
              </p>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="self-service help for employees with a Generative-AI powered chatbot "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if a user needs to{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  troubleshoot a paper jam
                </span>{" "}
                in the printer, a Gen AI-powered chatbot would ask several
                related problems and then offer the right answer that helps
                address the issue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 2:
                </span>{" "}
                if an employee wants to know how to claim insurance for a
                particular treatment, a workplace chatbot powered by Gen AI will
                surface the information to help him raise a claim without
                friction.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Easing IT operations
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                For IT teams, IT operations are an everyday hardship. They need
                to be steady in ticket handling and provide rapid yet meaningful
                assistance that solves problems quickly.
              </p>
              <BlogCta
                ContentCta="Automate your employee support with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                But, tasks such as IT asset management are repetitive and
                time-consuming, causing IT fatigue.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With applications layered with Generative AI properties, IT
                teams can streamline workflows, more flexible and agile than
                traditional AI, and cut off friction from the IT journeys.
              </p>
              <h4 className="font-section-normal-text-medium">Examples</h4>
              <img src={section_10} className="blog_image_top_bt"></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 1:
                </span>{" "}
                An ITSM manager can use Generative AI and steadily track
                employee devices and warn them of serious issues with their
                devices. If it is predicted that an employee is approaching{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  a password expiry,
                </span>{" "}
                a notification is escalated for the employee to help him set his
                password before it expires and causes disruptions in operational
                efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 2:
                </span>{" "}
                Generative AI helps with real-time monitoring and automation.
                So, an incident is detected, it streamlines workflows by sending
                an incident mitigation plan to the right person and helps reduce
                the impact.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Freeing up the HR team
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Onboarding and offboarding is the most mundane work for HR
                teams. An HR team can be seen juggling administrative work and
                onboarding operations. The result is either of the work can
                happen haphazardly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The wait time for new hire onboarding increases, making him
                impatient, whereas administrative tasks pile up, creating a
                backlog.
              </p>

              <h4 className="font-section-normal-text-medium">Examples</h4>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="Employee onboarding                 "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 1:
                </span>{" "}
                Generative AI streamlines the onboarding processes by automating
                mundane tasks such as facilitating documentation, scheduling new
                hire introduction meet, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 2:
                </span>{" "}
                Generative AI makes the knowledge base easily accessible to help
                a new hire learn company policies and various operation aspects.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Sales and marketing
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                There are myriad ways sales and marketing can benefit from
                Generative AI. Marketing and sales must build brand awareness
                through different materials for social media posts or website
                publications. Generative AI helps reduce the time to create and
                polish a draft with meaningful content.
              </p>

              <h4 className="font-section-normal-text-medium">Examples</h4>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                <span className="font-section-normal-text-testimonials color-black">
                  Example 1:
                </span>{" "}
                Sales and marketing must communicate with the prospects with
                different types of email templates. Generative AI helps reduce
                the labor of creating a draft from scratch, generating it
                instantly to revise and use for communications. As a result,
                sales and marketing can focus more on the human aspect of
                collaboration and building relationships.
              </p>
              <img
                src={section_12}
                className="blog_image_top_bt"
                alt="Content generation using Generative AI"
              ></img>
              <h3 className="font-section-sub-header-small-home">
                Can Generative AI be trustworthy?
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI or its foundation models are built based on
                unsupervised learning. The chances are high for the models to
                hallucinate. Or it can even lack explainability, meaning
                Generative AI cannot explain how it arrives at decisions or
                predictions due to its deep learning models with billions of
                parameters.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The best way to use Generative AI ethically is to provide enough
                human oversight to ensure it does not surface biased or harmful
                responses. It can be done during the model training or
                fine-tuning.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, model training should be based on clean data, a high-level
                task requiring intense human oversight.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In addition, the model deployment is not the end of the
                iteration. The process should be ongoing for performance review
                and then for making changes to the model to enable it to perform
                without bias.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Train, fine-tune, or as is, models
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Different scenarios can determine whether a business needs a
                custom, a fine-tuned, or a pre-trained model.
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Custom solutions
                  </span>
                  provide use-case-specific solutions. So, you need quality data
                  to create your workflows. It gives you much control and makes
                  it easy to update the model workings whenever needed.
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Fine-tuned models
                  </span>
                  allow us to tweak only the final layer of the LLM models. This
                  model solution effectively implements one or two use cases for
                  a small project. It requires less computational power and
                  hardware investment.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Pre-trained models
                  </span>
                  such as ChatGPT, Microsoft AI copilot, and Claude are useful
                  if your purpose is generic, such as Q&A only. Through an API
                  integration, you can implement pre-trained models and gain
                  good business results.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                So far, the article aims to provide a basic understanding of
                Generative AI, and we have supplied all the necessary
                information to make you aware of the technology and its
                capabilities.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In the enterprise setting, Generative AI can offer multiple use
                cases that can be applied across a wide range of business
                functions and streamline complicated work processes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to savor the taste of Generative AI, you can start
                small, and API-layered Generative AI solutions are a good
                option.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Want to empower your HR and IT operations with Generative AI?
                Workativ virtual assistants can complement your business
                objectives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://outlook.office365.com/owa/calendar/Bookademo@workativ.com/bookings/">
                  Get in touch with us for a personalized demo
                </a>{" "}
                today.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h2 class="font-section-sub-header-small color-black">
                What are some potential ethical concerns about the
                trustworthiness of Generative AI-generated content and the need
                for human oversight?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLM-generated content, like ChatGPT or other GPT 3.5-produced
                content, is known to cause ethical concerns due to its cut-off
                data. Ethical concerns remain a big challenge for custom
                Generative AI solutions, too. While they hold immense potential
                to unlock extended customization for use cases, they can be
                sentient and produce biased or harmful responses, especially if
                they undergo unsupervised learning for training. Hence, ensuring
                human oversight during model training and deployment is crucial
                to mitigate these risks. To improve model performance and
                address biases, a model must undergo continuous monitoring and
                refinement.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                What is the difference between Generative AI and traditional AI,
                depending on their ability to generate real-time responses and
                handle various business functions? Compared to traditional AI,
                is Generative AI suitable for specific industries and use cases?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unlike traditional AI, Generative AI models such as GPTs or
                Generative Pre-trained Transformers leverage large language
                models trained on millions or billions of data parameters to
                gain the ability to generate human-like responses by
                understanding NLP queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This makes Generative AI understand prompts related to industry
                use cases and surface contextual answers for business functions
                from HR operations to customer support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, traditional AI models follow certain business functions
                or predictions. So, their capabilities are limited to specific
                use cases and may not meet the industry's diverse customization
                needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI always stays ahead of traditional AI in terms of
                versatility and real-time response efficiency.
              </p>
              <h2 class="font-section-sub-header-small color-black">
                Besides basic applications, how can Generative AI drive business
                value and contribute to strategic objectives for customer
                support or HR operations?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI unlocks incredible efficiencies to augment the
                state of automation for existing business operations to increase
                user experience and streamline operations, driving CEOs to
                invest in Generative AI to leverage extended business benefits.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There are several ways in which businesses can drive value and
                strategic objectives beyond the basic applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, Generative AI can help reimagine customer support
                by reducing MTTR, enhancing response efficiency, and delivering
                hyper-personalization to customer queries. As a result,
                businesses can improve CSAT and NPS, leading to customer
                retention.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, Generative AI can help HR operations automate
                repetitive tasks and streamline operations for a better user
                experience. Onboarding automation is one of the best use cases
                that free HR of repetitive workloads and helps focus on
                strategic initiatives.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In essence, Generative AI transforms how businesses manage work
                and deliver value to their stakeholders.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              How can Enterprises Leverage Generative AI?
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/chatgpt-vs-conversational-ai"
              className="font-section-normal-text-testimonials"
            >
              Why Is ChatGPT Not The Answer To Your Enterprise Conversational AI
              Needs?
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
